import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _d017bd12 = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _3fef74af = () => interopDefault(import('../pages/entities/index.vue' /* webpackChunkName: "pages/entities/index" */))
const _a0c34712 = () => interopDefault(import('../pages/perfil.vue' /* webpackChunkName: "pages/perfil" */))
const _608579e5 = () => interopDefault(import('../pages/admin/actions/index.vue' /* webpackChunkName: "pages/admin/actions/index" */))
const _57aec62e = () => interopDefault(import('../pages/admin/calculation.vue' /* webpackChunkName: "pages/admin/calculation" */))
const _8cb5440c = () => interopDefault(import('../pages/admin/children-registrations/index.vue' /* webpackChunkName: "pages/admin/children-registrations/index" */))
const _7c5c0736 = () => interopDefault(import('../pages/admin/commands/index.vue' /* webpackChunkName: "pages/admin/commands/index" */))
const _0b66bbfe = () => interopDefault(import('../pages/admin/cpf-block/index.vue' /* webpackChunkName: "pages/admin/cpf-block/index" */))
const _2588e3b9 = () => interopDefault(import('../pages/admin/dashboard.vue' /* webpackChunkName: "pages/admin/dashboard" */))
const _e6ce52d4 = () => interopDefault(import('../pages/admin/dispatches/index.vue' /* webpackChunkName: "pages/admin/dispatches/index" */))
const _8987df20 = () => interopDefault(import('../pages/admin/email-templates/index.vue' /* webpackChunkName: "pages/admin/email-templates/index" */))
const _019c29a7 = () => interopDefault(import('../pages/admin/exports/index.vue' /* webpackChunkName: "pages/admin/exports/index" */))
const _e170dbb0 = () => interopDefault(import('../pages/admin/indexes/index.vue' /* webpackChunkName: "pages/admin/indexes/index" */))
const _0cc1e8d4 = () => interopDefault(import('../pages/admin/ips/index.vue' /* webpackChunkName: "pages/admin/ips/index" */))
const _6cea1f67 = () => interopDefault(import('../pages/admin/newsletter/index.vue' /* webpackChunkName: "pages/admin/newsletter/index" */))
const _3deeda2c = () => interopDefault(import('../pages/admin/permissions/index.vue' /* webpackChunkName: "pages/admin/permissions/index" */))
const _5f16043b = () => interopDefault(import('../pages/admin/security-registrations/index.vue' /* webpackChunkName: "pages/admin/security-registrations/index" */))
const _73a10964 = () => interopDefault(import('../pages/admin/statistics.vue' /* webpackChunkName: "pages/admin/statistics" */))
const _0993c6f0 = () => interopDefault(import('../pages/admin/tokens/index.vue' /* webpackChunkName: "pages/admin/tokens/index" */))
const _33184c70 = () => interopDefault(import('../pages/admin/users/index.vue' /* webpackChunkName: "pages/admin/users/index" */))
const _973aef88 = () => interopDefault(import('../pages/admin/winners/index.vue' /* webpackChunkName: "pages/admin/winners/index" */))
const _94891662 = () => interopDefault(import('../pages/auth/forgot.vue' /* webpackChunkName: "pages/auth/forgot" */))
const _f1bb8b26 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _99b1e96c = () => interopDefault(import('../pages/entities/actives.vue' /* webpackChunkName: "pages/entities/actives" */))
const _561e684f = () => interopDefault(import('../pages/admin/auth/forgot.vue' /* webpackChunkName: "pages/admin/auth/forgot" */))
const _c65a5226 = () => interopDefault(import('../pages/admin/auth/login.vue' /* webpackChunkName: "pages/admin/auth/login" */))
const _2e3646e8 = () => interopDefault(import('../pages/admin/dispatches/create.vue' /* webpackChunkName: "pages/admin/dispatches/create" */))
const _580b6f64 = () => interopDefault(import('../pages/admin/email-templates/create.vue' /* webpackChunkName: "pages/admin/email-templates/create" */))
const _a942643a = () => interopDefault(import('../pages/admin/security-registrations/create.vue' /* webpackChunkName: "pages/admin/security-registrations/create" */))
const _d97c8194 = () => interopDefault(import('../pages/admin/tokens/create.vue' /* webpackChunkName: "pages/admin/tokens/create" */))
const _57a58c4e = () => interopDefault(import('../pages/admin/users/create.vue' /* webpackChunkName: "pages/admin/users/create" */))
const _5261c734 = () => interopDefault(import('../pages/admin/auth/reset/_token.vue' /* webpackChunkName: "pages/admin/auth/reset/_token" */))
const _bbc276f2 = () => interopDefault(import('../pages/admin/email-templates/edit/_id.vue' /* webpackChunkName: "pages/admin/email-templates/edit/_id" */))
const _327838dc = () => interopDefault(import('../pages/admin/security-registrations/edit/_id.vue' /* webpackChunkName: "pages/admin/security-registrations/edit/_id" */))
const _2fedeef2 = () => interopDefault(import('../pages/admin/users/edit/_id.vue' /* webpackChunkName: "pages/admin/users/edit/_id" */))
const _72fa9ce6 = () => interopDefault(import('../pages/admin/actions/_id.vue' /* webpackChunkName: "pages/admin/actions/_id" */))
const _9df2d23c = () => interopDefault(import('../pages/admin/children-registrations/_id.vue' /* webpackChunkName: "pages/admin/children-registrations/_id" */))
const _c710f304 = () => interopDefault(import('../pages/admin/dispatches/_id.vue' /* webpackChunkName: "pages/admin/dispatches/_id" */))
const _54452a48 = () => interopDefault(import('../pages/admin/ips/_ip.vue' /* webpackChunkName: "pages/admin/ips/_ip" */))
const _073dd963 = () => interopDefault(import('../pages/admin/security-registrations/_id.vue' /* webpackChunkName: "pages/admin/security-registrations/_id" */))
const _0ab28f0e = () => interopDefault(import('../pages/admin/validation/_prize.vue' /* webpackChunkName: "pages/admin/validation/_prize" */))
const _eade6e34 = () => interopDefault(import('../pages/auth/reset/_token.vue' /* webpackChunkName: "pages/auth/reset/_token" */))
const _2a1d83e8 = () => interopDefault(import('../pages/entities/edit/_id.vue' /* webpackChunkName: "pages/entities/edit/_id" */))
const _763e5252 = () => interopDefault(import('../pages/entities/_id.vue' /* webpackChunkName: "pages/entities/_id" */))
const _c5101262 = () => interopDefault(import('../pages/validation/_id.vue' /* webpackChunkName: "pages/validation/_id" */))
const _03748df7 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _d017bd12,
    name: "admin"
  }, {
    path: "/entities",
    component: _3fef74af,
    name: "entities"
  }, {
    path: "/perfil",
    component: _a0c34712,
    name: "perfil"
  }, {
    path: "/admin/actions",
    component: _608579e5,
    name: "admin-actions"
  }, {
    path: "/admin/calculation",
    component: _57aec62e,
    name: "admin-calculation"
  }, {
    path: "/admin/children-registrations",
    component: _8cb5440c,
    name: "admin-children-registrations"
  }, {
    path: "/admin/commands",
    component: _7c5c0736,
    name: "admin-commands"
  }, {
    path: "/admin/cpf-block",
    component: _0b66bbfe,
    name: "admin-cpf-block"
  }, {
    path: "/admin/dashboard",
    component: _2588e3b9,
    name: "admin-dashboard"
  }, {
    path: "/admin/dispatches",
    component: _e6ce52d4,
    name: "admin-dispatches"
  }, {
    path: "/admin/email-templates",
    component: _8987df20,
    name: "admin-email-templates"
  }, {
    path: "/admin/exports",
    component: _019c29a7,
    name: "admin-exports"
  }, {
    path: "/admin/indexes",
    component: _e170dbb0,
    name: "admin-indexes"
  }, {
    path: "/admin/ips",
    component: _0cc1e8d4,
    name: "admin-ips"
  }, {
    path: "/admin/newsletter",
    component: _6cea1f67,
    name: "admin-newsletter"
  }, {
    path: "/admin/permissions",
    component: _3deeda2c,
    name: "admin-permissions"
  }, {
    path: "/admin/security-registrations",
    component: _5f16043b,
    name: "admin-security-registrations"
  }, {
    path: "/admin/statistics",
    component: _73a10964,
    name: "admin-statistics"
  }, {
    path: "/admin/tokens",
    component: _0993c6f0,
    name: "admin-tokens"
  }, {
    path: "/admin/users",
    component: _33184c70,
    name: "admin-users"
  }, {
    path: "/admin/winners",
    component: _973aef88,
    name: "admin-winners"
  }, {
    path: "/forgot",
    component: _94891662,
    name: "auth-forgot"
  }, {
    path: "/login",
    component: _f1bb8b26,
    name: "auth-login"
  }, {
    path: "/entities/actives",
    component: _99b1e96c,
    name: "entities-actives"
  }, {
    path: "/admin/forgot",
    component: _561e684f,
    name: "admin-auth-forgot"
  }, {
    path: "/admin/login",
    component: _c65a5226,
    name: "admin-auth-login"
  }, {
    path: "/admin/dispatches/create",
    component: _2e3646e8,
    name: "admin-dispatches-create"
  }, {
    path: "/admin/email-templates/create",
    component: _580b6f64,
    name: "admin-email-templates-create"
  }, {
    path: "/admin/security-registrations/create",
    component: _a942643a,
    name: "admin-security-registrations-create"
  }, {
    path: "/admin/tokens/create",
    component: _d97c8194,
    name: "admin-tokens-create"
  }, {
    path: "/admin/users/create",
    component: _57a58c4e,
    name: "admin-users-create"
  }, {
    path: "/admin/reset/:token?",
    component: _5261c734,
    name: "admin-auth-reset-token"
  }, {
    path: "/admin/email-templates/edit/:id",
    component: _bbc276f2,
    name: "admin-email-templates-edit-id"
  }, {
    path: "/admin/security-registrations/edit/:id",
    component: _327838dc,
    name: "admin-security-registrations-edit-id"
  }, {
    path: "/admin/users/edit/:id?",
    component: _2fedeef2,
    name: "admin-users-edit-id"
  }, {
    path: "/admin/actions/:id",
    component: _72fa9ce6,
    name: "admin-actions-id"
  }, {
    path: "/admin/children-registrations/:id?",
    component: _9df2d23c,
    name: "admin-children-registrations-id"
  }, {
    path: "/admin/dispatches/:id",
    component: _c710f304,
    name: "admin-dispatches-id"
  }, {
    path: "/admin/ips/:ip",
    component: _54452a48,
    name: "admin-ips-ip"
  }, {
    path: "/admin/security-registrations/:id?",
    component: _073dd963,
    name: "admin-security-registrations-id"
  }, {
    path: "/admin/validation/:prize?",
    component: _0ab28f0e,
    name: "admin-validation-prize"
  }, {
    path: "/reset/:token?",
    component: _eade6e34,
    name: "auth-reset-token"
  }, {
    path: "/entities/edit/:id?",
    component: _2a1d83e8,
    name: "entities-edit-id"
  }, {
    path: "/entities/:id",
    component: _763e5252,
    name: "entities-id"
  }, {
    path: "/validation/:id?",
    component: _c5101262,
    name: "validation-id"
  }, {
    path: "/",
    component: _03748df7,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
