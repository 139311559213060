module.exports = {
  light: {
    // FOR ADMIN ONLY - DO NOT CHANGE
    primary: '#34a853',
    
    // DEFAULTS
    anchor: '#000',
    error: '#D50000',
    success: '#2E7D32',
    warning: '#FF8F00',
    
    // for defaults
    darkg: '#37474F', // blue-grey darken-3
    lightg: '#cbd5e1', // slate-300
    simpleg: '#607D8B', // blue-grey

    // for dashboard
    dmain: '#0277BD',
    dinfo: '#0097a7',
    daccent: '#FF6F00',
    
    // FOR SITE
    wmain: '#febd00',
    wsecondary: '#ff4300'
  },
  dark: {
    // FOR ADMIN ONLY - DO NOT CHANGE
    primary: '#845adf',

    // for defaults
    darkg: '#37474F', // blue-grey darken-3
    lightg: '#cbd5e1', // slate-300
    simpleg: '#607D8B', // blue-grey

    // for dashboard
    dmain: '#0277BD',
    dinfo: '#0097a7',
    daccent: '#FF6F00',
  }
}
