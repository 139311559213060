<template>
	<v-app id="home-layout" light>
		<nuxt style="flex: 1; width: 100%;" />
		<cookie-banner />
	</v-app>
</template>

<script>
export default {
	name: 'HomeLayout',
	components: { CookieBanner: () => import('~/components/website/CookieBanner'),  },
	mounted(){
		this.$vuetify.theme.dark = false
	}
}
</script>

<style lang="scss" src="~/assets/css/website/index.scss" />
